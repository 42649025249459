@import '../../../bps/components/carousel/carousel.scss';

.carousel {
  &__page-info {
    color: $whiteColor;

    .grid__element--bg-color1 &,
    .grid__element--bg-color2 & {
      color: $brandSecondaryColor;
    }
  }

  &__button-icon {
    color: $whiteColor;

    .grid__element--bg-color1 &,
    .grid__element--bg-color2 & {
      color: $brandSecondaryColor;
    }
  }

  &__button {
    @media (max-width: $screen-mobile-landscape-max) {
      height: 50px;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
    }

    &--next {
      padding-right: 30px;
    }

    &--prev {
      padding-left: 30px;
    }
  }

  &__pagination-wrapper {
    align-items: baseline;
    margin: 0 0 -13px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }
  }
}
