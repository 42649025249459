@import '../../../bps/components/cinemaTeaser/cinemaTeaser.scss';

.cinema-teaser {
  .grid__element--bg-color1 &,
  .grid__element--bg-color2 & {
    .cinema-teaser__headline,
    .carousel__page-info {
      color: $grayDarkColor;
    }

    .carousel__button-icon {
      color: $grayDarkColor;

      &::before {
        color: $grayDarkColor;
        text-shadow: $carouselButtonTextShadow;
      }
    }
  }

  &__link {
    .button--secondary {
      background: $brandPrimaryColor;
      border-color: currentColor;
      color: $whiteColor;

      &--hover,
      &:hover {
        text-decoration: none;
        border: 1px solid $buttonPrimaryBackgroundColor;
        color: $buttonPrimaryTextColor;
        background: $buttonPrimaryBackgroundColor;
      }
    }
  }
}
