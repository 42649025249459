.button {
  $root: &;
  font-family: inherit;
  transition: background .15s linear, border-color .15s linear, color .15s linear;
  cursor: pointer;
  line-height: $buttonPrimaryHeightMobile;
  text-align: center;
  padding: 0 8px;
  width: auto;
  position: relative;
  color: $buttonTextColor;
  font-weight: $fontWeightRegular;
  border: $buttonBorderWidth solid $buttonBorderColor;
  border-radius: $buttonBorderRadius;
  background: $buttonBackgroundColor;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0;
  font-size: $buttonFontSizeMobile;

  @media (min-width: $screen-tablet-portrait) {
    font-size: $buttonFontSizeDesktop;
    line-height: $buttonPrimaryHeightDesktop;
  }

  @media (min-width: $screen-tablet-landscape) {
    padding: 0 24px;
  }

  &::after {
    font-weight: $fontWeightBold;
  }

  &--hover,
  &:hover,
  &:focus {
    color: $buttonHoverTextColor;
    background: $buttonHoverBackgroundColor;
    text-decoration: none;
  }

  &:focus {
    outline-offset: 4px;
  }

  &--active,
  &:active {
    outline: none;
    transform: translate(1px, 1px);
  }

  &--loading:not(.button--hide-loading) {
    pointer-events: none;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      cursor: not-allowed;
      z-index: 10;
      position: absolute;
      background: $buttonBackgroundColor;
      left: 0;
      top: 0;
    }

    &::after {
      font-family: iconfont, sans-serif;
      content: map-get($icons, 'loading') !important;
      cursor: not-allowed;
      z-index: 20;
      position: absolute;
      animation: spin 4s linear infinite;
      font-size: 26px !important;
      width: 26px;
      font-weight: 100 !important;
      margin: auto;
      padding: 0 !important;
      line-height: 26px !important;
      height: 26px;
      top: -300px;
      right: -300px;
      bottom: -300px;
      left: -300px;
    }

    &:hover {
      background: $buttonBackgroundColor;
    }
  }

  &--link {
    background: transparent;
    display: inline-block;
    width: auto;
    border: 0;
    border-radius: 0;
    color: $grayDarkColor;
    text-decoration: underline;
    padding: 0;
    letter-spacing: 0;

    &:hover,
    &:focus  {
      color: $grayDarkColor;
      background: transparent;
      text-decoration: underline;
    }

    &:active {
      outline: none;
    }
  }

  &--delete {
    color: $brandPrimaryColor !important;
    border-color: currentColor;
    background: $whiteColor !important;
    padding: 0 8px;

    &:hover {
      color: $brandSecondaryColor !important;
    }
  }

  &--height-auto {
    height: auto;
    line-height: normal;
  }

  &--primary,
  &--secondary {
    background: $buttonPrimaryBackgroundColor;
    border-color: $buttonPrimaryBorderColor;
    color: $buttonPrimaryTextColor;
    display: inline-block;
    width: auto;
    font-weight: $fontWeightThin;
    text-transform: uppercase;
    padding: 0 16px;
    font-size: $buttonFontSizeMobile;

    @media (min-width: $screen-mobile-landscape) {
      min-width: 200px;
      padding: 0 32px;
    }

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
    }

    &::after {
      display: inline-block;
      padding-left: 12px;
      font-weight: $fontWeightBold;
      font-size: 8px;
      line-height: $buttonPrimaryHeightMobile;

      @media (min-width: $screen-tablet-portrait) {
        padding-left: 8px;
        font-size: 10px;
        line-height: $buttonPrimaryHeightDesktop;
      }
    }

    &--hover,
    &:hover,
    &:focus  {
      background: $buttonPrimaryHoverBackgroundColor;
      border-color: $buttonPrimaryHoverBackgroundColor;
      color: $buttonPrimaryHoverTextColor;
    }

    &.button--loading::before {
      background: $buttonPrimaryBackgroundColor;
    }
  }

  &--secondary {
    background: $buttonSecondaryBackgroundColor;
    border-color: $buttonSecondaryBorderColor;
    color: $buttonSecondaryTextColor;
    font-size: 14px;
    font-weight: $fontWeightThin;

    &--hover,
    &:hover,
    &:focus  {
      background: $buttonSecondaryHoverBackgroundColor;
      color: $buttonSecondaryHoverTextColor;
      border-color: $buttonSecondaryHoverBackgroundColor;
    }

    &.button--loading::before {
      background: $buttonSecondaryBackgroundColor;
    }
  }

  &--primary-small {
    padding: 0 32px;
    background: $buttonPrimaryBackgroundColor;
    color: $buttonPrimaryTextColor;
    display: inline-block;
    width: auto;
    border-color: $buttonPrimaryBorderColor;
    text-transform: uppercase;
    font-size: $buttonFontSizeMobile;
    font-weight: $fontWeightBold;
    height: $buttonSecondaryHeightMobile;
    line-height: $buttonSecondaryHeightMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
      line-height: $buttonSecondaryHeightDesktop;
    }

    &--hover,
    &:hover,
    &:focus {
      background: $buttonPrimaryHoverBackgroundColor;
      color: $buttonPrimaryHoverTextColor;
    }
  }

  &--secondary-small {
    padding: 0 32px;
    background: $ctaPrimaryColor;
    color: $buttonSecondaryTextColor;
    display: inline-block;
    text-transform: uppercase;
    border-color: $ctaPrimaryColor;
    font-size: $buttonFontSizeMobile;
    font-weight: $fontWeightBold;
    height: $buttonSecondaryHeightMobile;
    line-height: $buttonSecondaryHeightMobile;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $buttonFontSizeDesktop;
      line-height: $buttonSecondaryHeightDesktop;
    }

    &--hover,
    &:hover,
    &:focus {
      background: $ctaPrimaryHoverColor;
      color: $buttonSecondaryHoverTextColor;
    }
  }

  &--secondary-smaller {
    background: $ctaPrimaryColor;
    color: $buttonSecondaryTextColor;
    display: inline-block;
    font-weight: $fontWeightBold;
    text-transform: uppercase;
    border-color: $buttonSecondaryBorderColor;
    font-size: 10px;
    line-height: 1;
    white-space: inherit;
    overflow: visible;
    text-overflow: inherit;
    padding: 8px !important;
    height: auto;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 12px;
    }

    &--hover,
    &:hover,
    &:focus {
      background: $ctaPrimaryHoverColor;
      color: $buttonSecondaryHoverTextColor;
    }
  }

  &--text {
    font-size: $fontSizeSmall;
    background: none;
    color: $brandPrimaryColor;
    display: inline-block;
    font-weight: $fontWeightRegular;
    text-transform: uppercase;
    text-align: left;
    width: auto;
    height: auto;
    padding: 2px 0;
    line-height: 120%;
    border: 0 none;
    border-bottom: currentColor 1px solid;
    white-space: inherit;
    border-radius: 0;

    @media (min-width: $screen-tablet-landscape) {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
    }

    &--hover,
    &:hover,
    &:focus {
      background: none;
      color: $brandPrimaryColor;
    }

    &-arrow {
      @include iconFont(arrowRightBold, after) {
        display: inline-block;
        padding-left: 12px;
        font-weight: $fontWeightBold;
        font-size: 8px;

        @media (min-width: $screen-tablet-portrait) {
          padding-left: 8px;
          font-size: 10px;
        }
      }
    }
  }

  &--alt {
    background: $buttonBackgroundColor;
    border-color: $buttonBorderColor;
    color: $buttonTextColor;
    width: 100%;

    &::after {
      color: inherit;
    }

    &:hover,
    &:focus {
      color: $buttonHoverTextColor;
      background: $buttonHoverBackgroundColor;
      border-color: $buttonHoverBorderColor;

      &::after {
        color: inherit;
      }
    }
  }

  &--cancel {
    background: transparent !important;
    display: inline-block;
    width: auto;
    border: 0 none;
    color: $brandPrimaryColor !important;
    text-decoration: underline !important;
    padding: 0 !important;
    letter-spacing: 0;
    font-weight: $fontWeightRegular !important;
    text-transform: inherit !important;
    font-size: 14px !important;
    text-align: right !important;
    line-height: $buttonPrimaryHeightDesktop;

    &:hover,
    &:focus {
      color: $brandPrimaryColor !important;
      background: transparent;
      text-decoration: underline;
    }

    &:active {
      outline: none;
    }
  }

  &--light {
    display: inline-block;
    color: $textDarkestColor;
    background: rgb(255 255 255 / .9);
    border-color: $textLightColor;

    @include iconFont(arrowRight, after);

    @media (min-width: $screen-mobile-landscape) {
      min-width: 200px;
      width: auto;
    }

    &::after {
      display: inline-block;
      padding-left: 8px;
      font-size: 10px;
    }

    &--hover,
    &:hover,
    &:focus {
      background: rgb(255 255 255 / .9);
      color: $textDarkestColor;
    }
  }

  &--add-to-basket {
    background: $buttonAddToBasketBackgroundColor;
    border-color: $buttonAddToBasketBorderColor;
    color: $buttonAddToBasketTextColor;
    padding: 0;
    width: $buttonAddToBasketWidth;
    height: $buttonAddToBasketHeight;
    line-height: 40px;
    font-size: 12px;

    @include iconFont(cart, after) {
      font-size: 24px;
      transform: translate(1px, 8px);
    }

    &:hover,
    &:focus {
      background: $buttonAddToBasketHoverBackgroundColor;
      color: $buttonAddToBasketHoverTextColor;
      border-color: $buttonAddToBasketHoverBackgroundColor;
    }

    &.button--loading::before {
      background: $buttonAddToBasketHoverBackgroundColor;
      color: $buttonAddToBasketHoverTextColor;
    }
  }

  &--disabled {
    cursor: not-allowed;
    background: $buttonDisabledBackgroundColor;
    border-color: $buttonDisabledBorderColor;
    color: $buttonDisabledTextColor;

    &:hover {
      background: $buttonDisabledBackgroundColor;
      border-color: $buttonDisabledBorderColor;
      color: $buttonDisabledTextColor;
    }
  }

  &--type {
    &_back {
      padding: 0 30px 0 40px;

      &::after {
        right: auto;
        left: 15px;
        margin-left: 0;
        line-height: 0;
        transform: rotate(-180deg);
      }
    }
  }

  &--size {
    &_40 {
      line-height: 36px;
      height: 40px;
      font-size: $baseFontSize;
    }

    &_30 {
      line-height: 26px;
      height: 30px;
      font-size: $baseFontSize;
      padding: 5px 15px;
    }
  }

  &--icon-arrow-right {
    @include iconFont(arrowRight, after);

    &::after {
      display: inline-block;
      padding-left: 8px;
      font-size: 10px;
      font-weight: $fontWeightBold;
    }
  }

  &--icon-arrow-left {
    @include iconFont(arrowLeft, before);

    &::before {
      display: inline-block;
      padding-right: 8px;
      font-size: 10px;
      font-weight: $fontWeightBold;
    }
  }

  &--icon {
    padding: 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 40px;
    }

    &::after {
      font-size: 12px;
      font-weight: $fontWeightSemiBold;
      position: relative;
      padding: 0 0 2px 10px;
      line-height: 56px;
    }

    &_reload {
      @include iconFont(reload, after);
    }

    &_close {
      @include iconFont(close, after);
    }

    &_next {
      @include iconFont(arrowRight, after);
    }

    &_back {
      @include iconFont(arrowLeft, before) {
        font-size: 12px;
        font-weight: $fontWeightSemiBold;
        position: relative;
        padding: 0 10px 2px 0;
        line-height: 56px;
      }
    }

    &_cart {
      @include iconFont(cart, after) {
        font-size: $fontSizeXMedium;
        font-weight: $fontWeightSemiBold;
      }
    }

    &_edit {
      @include iconFont(edit, after);
    }

    &_remove {
      @include iconFont(trash, after);
    }
  }

  &--paypal-checkout {
    background-color: $paypalcheckout;
    padding: 0;
    max-width: 210px;
    height: $buttonPrimaryHeightMobile;
    border: $buttonBorderWidth solid $paypalcheckout;

    @media (min-width: $screen-tablet-portrait) {
      height: 44px;
      margin: 0 !important;
    }

    &:hover {
      background-color: $paypalcheckout;
      border-color: $paypalcheckout;
    }

    i {
      display: inline-block;
      padding: 0;
      margin: 0;
    }
  }

  &--hide {
    display: none !important;
  }

  &--inactive {
    opacity: .4;
  }
}
