@import '../../utilities/scss/teasers/teaserLayout.scss';

.cinema-teaser {
  @include teaserContainer();

  .grid.teaser-in-column & {
    padding: 0;
  }

  &__headline {
    @include teaserHeadline();
  }

  &__link {
    @include teaserLink();

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: 18px;
    }
  }

  &__items-container {
    @include teaserItemsContainer();
    width: 100%;
  }

  &__products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    &.products-list--type_box {
      justify-content: flex-start;
    }
  }

  .product-box__inner {
    border: 0;
  }

  &__section {
    body:not(.page-detail-view, .page-home) & {
      width: 100%;
      position: relative;
      padding: 0;
      min-height: 200px;

      @media (min-width: $screen-tablet-landscape) {
        padding: 0;
      }

      &:nth-child(even),
      &:nth-child(odd) {
        background: inherit;

        .read-more {
          &__btn {
            background: inherit;
          }
        }
      }
    }
  }

  &--smart-product-list {
    width: 100%;
  }

  &__below {
    order: 5;
  }
}
