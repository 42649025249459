@mixin teaserContainer {
  display: flex;
  flex-flow: row wrap;
}

@mixin teaserHeadline {
  @include fontSize($teaserHeadlineFontSize);
  font-weight: $teaserHeadlineFontWeight;
  order: 1;
  margin: 0 0 15px;
  color: $teaserHeadlineColor;
  flex-grow: 1;

  @media (min-width: $screen-tablet-portrait) {
    margin: 0 0 30px;
    align-self: center;
    width: 65%;
  }
}

@mixin teaserLink {
  flex-grow: 1;
  order: 4;
  text-align: center;
  max-width: 100%;
  margin-top: 20px;

  @media (min-width: $screen-tablet-portrait) {
    order: 2;
    width: 35%;
    text-align: right;
    padding: 0;
    margin-top: -8px;
  }
}

@mixin teaserItemsContainer {
  width: auto;
  order: 3;
  position: relative;

  .tabs-component-panel & {
    margin: 0;
  }
}
